

.my-modal  {
 color:black;
 
 align-items: center;
align-content: center;
 background-color: transparent;
 margin-top: 0px;
 margin-left: 50px;
width: 100% !important;
height: 100% !important;
}

  
    
      
  .modal-backdrop {
    background-color: transparent;
   

  }

  .modal-content {

   align-content: center;
    
  }
    

  

