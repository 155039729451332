.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.linkText {
  color: white;
}

.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: transparent;
  padding: 30px;
}
.login__name {
  font-weight: 400;
  font-size: 22px;
  margin-top: 0px;
  margin-left: 0px;
  color:white;
}
.login__container img {
  height:50px;
  width:380px;
}
.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;

}
.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: grey;
}
.login__google {
  background-color: #4285f4;
}
.login div {
  margin-top: 7px;
}
