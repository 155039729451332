

.dashboard__container p{
  color:black !important;
}

.container1 h5 {
  color:white;
  margin-left: 65px;
}

 .container1 img{
  margin-left:25px;
  border-radius: 20%;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
 
  
}

.viewAreaHeadline1 h2{
  /* margin:auto; */
  padding: 20px 0 0 25px;
  color: white;
  font-size: 32x;
  text-align: center;
  margin-right: 5%;
}
.container2 {
  margin-top: 40px;
  width:60%;
  margin-left: 15%;
}