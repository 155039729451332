/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
 @media only screen and (max-width: 750px){
  body {
    position: relative;
    color: white;
    height: 100vh;
    background: radial-gradient(
      48.73% 48.73% at 36.08% 50%,
      #263053 0%,
      #1b2547 45.09%,
      #141b34 77.08%,
      #111629 100%
    );
    font-family: sans-serif;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  :root {
    --tagsBackground: #9440f3;
    --tagsText: #ffffff;
    --tagsBackgroundHoverActive: #2cc0a0;
    --tagsTextHoverActive: #ffffff;
    --searchBackground: #18191f;
    --searchText: #ffffff;
    --searchPlaceHolder: #575a77;
    --playerBackground: #18191f;
    --titleColor: #ffffff;
    --timeColor: #ffffff;
    --progressSlider: #9440f3;
    --progressUsed: #ffffff;
    --progressLeft: #151616;
    --volumeSlider: #9440f3;
    --volumeUsed: #ffffff;
    --volumeLeft: #151616;
    --playlistBackground: #18191f;
    --playlistText: #575a77;
    --playlistBackgroundHoverActive: #18191f;
    --playlistTextHoverActive: #ffffff;
  }

  .Mainframe {
    height: calc(100% - 95px);
  }

  /* SIDEBAR Anfang */

  .SideBarContainer {
    width: 300px !important;
    height: 100%;
    overflow: hidden;
  }

  .SideBar {
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
    text-align: left;
    color: white;
    display: none;
  }

  .SideBar h3 {
    padding-top: 5px;
    padding-left: 10px;
    font-weight: 700;
    text-align: center;
  }


  .SideBar h5 {
    margin-top: 16px;
    margin-left: 10px;
  }

  .SearchContainer {
    display: flex;
  }

  .SearchContainer svg {
    margin-top: 19px;
    margin-right: -5px;
    width: 1.2em;
    height: 1.2em;
    stroke-width: .8px;
  }

  /* h4 { margin-top:16px;}
  h4 { margin-left:10px;}
  h4 { text-align: center;} */
  Button {
    margin-top: 12px;
  }
  Button {
    align-items: center;
  }

  .textSearch {
    background: rgba(0, 0, 0, 0.28);
    border-radius: 5px;
    border: none;
    width: 100%;
    height: 50px;
    outline: none;
    color: white;
    padding: 0 10px 0 10px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.05px;
    line-height: 40px;
  }

  .textSearch:focus {
    /* animation: blueAnimation 1s infinite; */
  }

  .filterHeadlineContainer{
    display: flex;
  }

  .filterHeadlineContainer svg{
    width: 1.2em;
    height: 1.2em;
    stroke-width: 3px;
    margin-top: 19px;
    margin-right: -5px;
  }

  .dropBtn {
    border: 2px solid white !important;
    border-radius: 5px !important;
    color: white !important;
    margin-right: 5px;
    margin-left: 3px;
    font-weight: 600 !important;
  }

  .dropBtn:hover {
    color: #1b2547 !important;
    background-color: white !important;
  }

  .musicFilter .dropdown-item {
    font-weight: 700;
    color: #263053;
  }

  .musicFilter input[type="checkbox"] {
    /* removing default checkbox styling */
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;

    color: currentColor;
    width: 20px;
    height: 20px;
    border: 4px solid #263053;
    border-radius: 6px;

    display: grid;
    place-content: center;
  }

  .musicFilter input[type="checkbox"]::before {
    content: "";
    width: 12px;
    height: 12px;
    transform: scale(0);
    transition: 60ms transform ease-in-out;
  }

  .musicFilter input[type="checkbox"]:checked::before {
    transform: scale(.7);
    background-color: #263053;
    border-radius: 3px;
  }

  .Branding {
    padding: 10px 0 10px 0;
  }

  .brandname h4 {
    font-weight: 400;
    font-size: 22px;
    margin-top: 20px;
    margin-left: -20px ;
  }

  .brandlogo img {
    margin-left: 5px;
    margin-top: 9px;
    transform: scale(0.8);
  }

  .NavButtons button {
      background: rgba(255, 243, 243, 0.1);
      border-radius: 10px;
      border: none;
      border-color: rgba(255, 243, 243, 0.1);
      margin-left: 0 !important;
      padding: 10px 5px 10px 5px;
      font-weight: 600;
      font-size: 16px;
  }

  .buttontest {
    background: rgba(255, 243, 243, 0.1);
  }
  /* SIDEBAR ENDE */

  /* ViewArea Anfang */

  .ViewArea {
    overflow-y: clip;
    position: absolute;
    height: calc(100% - 100px);
  }

  .viewAreaHeader {
    height: 80px;
    margin: 0 !important;
  }

  .viewAreaHeadline {
    height: 100%; 
  }

  .viewAreaHeadline h2{
    /* margin:auto; */
    padding: 20px 0 0 25px;
    color: white;
    font-size: 28px;
  }

  .viewAreaContent {
    margin: 0 !important;
    overflow-y: scroll;
    height: 100%;
  }

  .profileButtonContainer {
    padding-right: 30px !important;
  }


  .profileButton {
    margin: 20px 0;
    background: white;
    border-radius: 10px;
    border: none;
    width: 50px;
    height: 50px;
    padding: 5px;

    transition-duration: .4s;
  }

  .profileButton img{
    height: 100%;
  }

  /* ViewArea ENDE */

  /* MusicGrid Anfang */

  .MusicGrid {
    margin-left: 0px !important;
    margin-top: 15px;
    padding-bottom: 95px;
    padding-left: 25px !important;
    padding-right: 40px !important;
  }

  .MusicGridListItem {
    margin-left: 0px !important;
    color: white;
    align-items: center;
    background: rgba(0, 0, 0, 0.29);
    border-radius: 13px;
    height: 74px;
    margin-bottom: 15px;
    transition-duration: 0.3s;
  }

  .MusicGridListItem * {
    max-height: 70px;
  }

  .InteractionArea {
    padding: 0 !important;
  }

  .InteractionArea Button {
    background-color: white;
    color: #1b2547;
    border: 3px solid white;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: -.3px;
    margin-right: 10px;
  }

  .InteractionArea .DownloadButton svg {
    width: 1.2em;
    height: 1.2em;
    stroke-width: 0.2px;
    margin-top: -2px;
  }

  .InteractionArea .SaveButton svg {
    width: 1.1em;
    height: 1.1em;
    stroke-width: 3px;
    margin-top: -4px;
    margin-right: 4px;
  }

  .Albumcover {
    padding: 0 5px 0 9px !important;
  }

  .Albumcover img {
    border-radius: 10px;
    height: 60px;
  }

  .Songname{
    padding-left: 0px !important;

  }

  .Songname h6{
    margin-bottom: 0;
    line-height: 20px;
    font-size: 12px;
  }

  .Songname p {
    font-weight: 400;
    font-size: 10px;
    color: #B8B8B8;
    margin-bottom: 0;
  }

  .progressContainer button {
    background-color: transparent;
    border: none;
    color: white;
  }

  .ProgressArea {
    display: none !important;
  }

  .playPauseContainer {
    display: flex;
    align-items: center;
    padding-right: 0 !important;
  }

  .playPauseContainer button svg {
    height: 30px;
    width: 30px;
  }

  .waveformContainer {
    padding-left: 0 !important;
  }

  /* MusicGrid Ende */

  /* MusicControl Anfang */

  .musicControl {
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    height: 95px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    color: white;
    overflow: hidden;
  }

  .MusicControlSongNameContainer {
    display: flex;
    align-items: flex-end;
  }

  .MusicControlSongNameContainer h4 {
    line-height: 0.5;
    font-weight: 500;
    font-size: 17px;
    padding: 0 50px 0 0;
    white-space: nowrap;
  }

  .MusicControlArtistNameContainer h5 {
    color: #b8b8b8;
    font-weight: 400;
    font-size: 16px;
    padding: 0 50px 0 0;
    white-space: nowrap;
  }

  .MusicProgressBarContainer {
    display: flex;
    align-items: center;
    line-height: 0;
    width: 100%;
    padding-right: 15px !important;
  }
  .MusicProgressBarContainer p {
    margin: auto;
  }
  .MusicProgressBarContainer img {
    width: 95%;
  }

  /* ProgressBar Styling Anfang */

  /* ProgressBar Styling Ende */

  .MusicProgressBarContainer input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    width: 90%;
    height: 10px;
    border-radius: 100px;
    margin: 0 10px 0 10px;
    overflow: visible;

    transition: 0.2s ease;
    cursor: pointer;
  }

  .MusicProgressBarContainer input[type='range']:focus {
    outline: none;
  }

  /* Chrome, Safari, Opera and Edge Chromium */

  .MusicProgressBarContainer input[type="range"]::-webkit-slider-runnable-track {
    /* background-color: #7c7c7c; */
    border-radius: 50px;
    height: 10px;
  }

  .MusicProgressBarContainer input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    box-shadow: -2px 0px 10px -3px #000000;
    height: 10px;
    width: 16px;
    border-radius: 20px;
    color: #fff;
    background: #ffffff;
    cursor: pointer;

    transition-duration: 0.2s;
  }

  .MusicProgressBarContainer input[type="range"]::-webkit-slider-thumb:hover {
    transform: scale(1.2);
    box-shadow: -2px 0px 10px -3px #226ba7;
  }

  /* Firefox */

  .MusicProgressBarContainer::-moz-range-track{
    /* background-color: #7c7c7c; */
    border-radius: 50px;
    height: 10px;
  }

  .MusicProgressBarContainer::-moz-range-thumb{
    box-shadow: -2px 0px 10px -3px #000000;
    height: 10px;
    width: 16px;
    border-radius: 20px;
    color: #fff;
    background: #ffffff;
    cursor: pointer;
    border: none;

    transition-duration: 0.2s;
  }

  /* .MusicProgressBarContainer input[type='range']::-webkit-slider-thumb, .MusicProgressBarContainer input[type=range]::-moz-range-thumb, .MusicProgressBarContainer input[type=range]::-ms-thumb {
    -webkit-appearance: none;
    box-shadow: -2px 0px 10px -3px #000000;
    height: 12px;
    width: 16px;
    border-radius: 20px;
    color: #fff;
    background: #ffffff;
    cursor: pointer;

    transition-duration: 0.2s;
  } */

  /* .MusicProgressBarContainer input[type='range']::-webkit-slider-thumb:hover, .MusicProgressBarContainer input[type=range]::-moz-range-thumb:hover, .MusicProgressBarContainer input[type=range]::-ms-thumb:hover {
    transform: scale(1.2);
    box-shadow: -2px 0px 10px -3px #226ba7;
  } */

  .MusicProgressBarBase {
    width: 90%;
    height: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100px;
    margin: 0 10px 0 10px;
  }

  .MusicProgressBarProgress {
    background-color: white;
    height: 100%;
    width: 50%;
    border-radius: 100px;
  }

  .TitleInfo {
    display: flex;
    align-items: center;
  }

  .TitleInfo img {
    margin: 0 10px 0 15px;
    /* height: 80%; */
    height: 80px;
    border-radius: 10px;
  }

  /* .TitleInfo {
    display: flex;
    align-items: center;
    height: 100%;

    img{
      margin: 0 10px 0 15px;
      height: 80%;
      border-radius: 10px;
    }
  } */

  .PlayControl {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .PlayControlButtons {
    margin: auto 10px auto 10px;
    height: 50%;
    display: flex;
    align-items: center;
    padding: 0 30px 0 0;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 100px;
  }

  .PlayControlButtons img {
    /* height: calc(100% - 20px); */
    height: 27px;
    width: 27px;
    margin-left: 30px;
  }

  .VolumeButtonContainer {
    display: flex;
    align-items: center;
  }

  .VolumeButtonBackground {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    margin: auto 10px auto 0px;
    height: 50%;
    display: flex;
    align-items: center;
  }

  .VolumeButtonBackground img {
    height: calc(100% - 20px);
    margin: 0 12px 0 12px;
  }

  /* MusicControl Ende */

  /* Additional Bootstrap styles Anfang */

  .h-90 {
    height: 90%;
  }
  .h-85 {
    height: 85%;
  }
  .h-15 {
    height: 15%;
  }
  .h-10 {
    height: 10%;
  }

  /* Additional Bootstrap styles Ende */

  .musicFilter label {
      margin: 0 0 5px 5px;
  }

  /* {
    padding: 10px 5px 5px 5px;
    background: rgba(0, 0, 0, 0.29);
    border-radius: 20px;
    margin: 10px 0 0 0 !important;
    text-align: center;
    transition-duration: 0.4s;
    margin-right: 10px;
  } */



  .artistCard img {
    border-radius: 15px;
    height: 150px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 15px -4px black;
  }

  .artistCard h5 {
    font-weight: 500;
    font-size: 18px;
    color: #fff, 0.75;
    letter-spacing: 1.2px;
  }

  .artistCard:hover {
    transform: scale(1.03);
    background: rgba(0, 0, 0, 0.29);
    background: radial-gradient(
      circle,
      rgba(12, 12, 66, 0.4) 0%,
      rgba(0, 12, 45, 0.4) 100%
    );

    -webkit-transition: all 0.9s ease;
  }

  .artistCardeins {
    padding: 10px 5px 5px 5px;
    background: rgba(0, 0, 0, 0.29);
    border-radius: 20px;
    margin: 10px 0 0 0 !important;
    text-align: center;
    transition-duration: 0.4s;
    margin-right: 10px;
    margin-left: 10px !important;
  }

  .artistCardeins img {
    border-radius: 15px;
    height: 150px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 15px -4px black;
  }

  .artistCardeins h5 {
    font-weight: 500;
    font-size: 18px;
    color: #fff, 0.75;
    letter-spacing: 1.2px;
  }

  .artistCardeins:hover {
    transform: scale(1.03);
    background: rgba(0, 0, 0, 0.29);
    background: radial-gradient(
      circle,
      rgba(12, 12, 66, 0.4) 0%,
      rgba(0, 12, 45, 0.4) 100%
    );

    -webkit-transition: all 0.9s ease;
  }

  /* active animation */

  @keyframes blueAnimation {
    0%,
    100% {
      box-shadow: 2px -2px 10px -4px #33d6ff, 2px 2px 10px -4px #00ccff,
        -2px 2px 10px -4px #66ffcc, -2px -2px 10px -4px #3366ff;
      opacity: 50%;
    }
    25% {
      box-shadow: 2px -2px 10px -4px #3366ff, 2px 2px 10px -4px #33d6ff,
        -2px 2px 10px -4px #00ccff, -2px -2px 10px -4px #66ffcc;
      opacity: 50%;
    }
    50% {
      box-shadow: 2px -2px 10px -4px #66ffcc, 2px 2px 10px -4px #3366ff,
        -2px 2px 10px -4px #33d6ff, -2px -2px 10px -4px #00ccff;
      opacity: 50%;
    }
    75% {
      box-shadow: 2px -2px 10px -4px #00ccff, 2px 2px 10px -4px #66ffcc,
        -2px 2px 10px -4px #3366ff, -2px -2px 10px -4px #33d6ff;
      opacity: 50%;
    }
  }
}
