@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap");

body {
  background-color: #1f4287;
  color: white;
  overflow: scroll;

}

.Apps1 {
  font-family: "Raleway", sans-serif;
  text-align: center;
  overflow: scroll;
  height: 90vh;
  margin-left:-100px;
 


  
}
h6 {
  margin: 0.1rem 0;
}
hr {
  background-color: #071e3d;
   color: #1f4287;
  height: 0.3rem;
  border: none;
  border-radius: 0.1rem;
  overflow: scroll;
 


  
}
.flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.container-pill {
 
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem auto;
  background-color: var(--ternary-color);
  padding: 1rem 3rem;
  border-radius: 1rem;
  max-width: 50%;
  overflow: scroll;
  display: flex;
      grid-template-columns: repeat(1, 4fr);
      grid-row-gap: 10px; 
      
      
}

.pills {
  cursor: pointer;
  background-color: #071e3d;
  padding: 0.7rem;
  border-radius: 0.4rem;
  margin: 0.2rem;
  user-select:none;
  

}

.Artistgrid {
  background-color: #071e3d;
  margin: 1rem auto;
  border-radius: 1rem;
  padding: 1rem;
  max-width: 25%;
  overflow: scroll; 
  box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
 
 
  
}

.image {
  max-width: 50%;
  align-items: center;
} 

img {
  max-width: 100%;
  border-radius: 0.4rem;
  margin: auto;
}

.content {
  padding: 0 2.5rem;
  overflow: scroll;
  margin-left: 40px;

}

.name {
  font-size: 1rem;
  font-weight: bolder;
  margin-left: 10px;
  margin-top: 10px;
}

  




.IconBarContainer {
    width: 45px !important;
    height: 100%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0);
  }

  .IconBar {
    flex-direction: column;
    display: flex;
    color: white;
    justify-content: center;
    padding: 15px 0 0 0;
  }

  .IconBar .icon {
    display: inline-block;
    margin: 20px 0 0 5px;
    cursor: pointer;
  }

  .IconBar .icon svg {
    transition-duration: .4s;
  }
  

  .IconBar .icon svg:hover {
    filter: drop-shadow(0px 0px 6px rgba(255, 255, 255, 0.747));
  }

  .IconBar .icon .whiteGlow {
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.486));
  }

 
 
  
